exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dla-kandydata-blog-js": () => import("./../../../src/pages/dla-kandydata-blog.js" /* webpackChunkName: "component---src-pages-dla-kandydata-blog-js" */),
  "component---src-pages-dla-kandydata-js": () => import("./../../../src/pages/dla-kandydata.js" /* webpackChunkName: "component---src-pages-dla-kandydata-js" */),
  "component---src-pages-dla-pracodawcy-blog-js": () => import("./../../../src/pages/dla-pracodawcy-blog.js" /* webpackChunkName: "component---src-pages-dla-pracodawcy-blog-js" */),
  "component---src-pages-dla-pracodawcy-js": () => import("./../../../src/pages/dla-pracodawcy.js" /* webpackChunkName: "component---src-pages-dla-pracodawcy-js" */),
  "component---src-pages-historie-sukcesow-js": () => import("./../../../src/pages/historie-sukcesow.js" /* webpackChunkName: "component---src-pages-historie-sukcesow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-dzialamy-js": () => import("./../../../src/pages/jak-dzialamy.js" /* webpackChunkName: "component---src-pages-jak-dzialamy-js" */),
  "component---src-pages-klienci-js": () => import("./../../../src/pages/klienci.js" /* webpackChunkName: "component---src-pages-klienci-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferty-pracy-js": () => import("./../../../src/pages/oferty-pracy.js" /* webpackChunkName: "component---src-pages-oferty-pracy-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-recruitment-agency-js": () => import("./../../../src/pages/recruitment-agency.js" /* webpackChunkName: "component---src-pages-recruitment-agency-js" */),
  "component---src-pages-referencje-js": () => import("./../../../src/pages/referencje.js" /* webpackChunkName: "component---src-pages-referencje-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-specialization-js": () => import("./../../../src/templates/specialization.js" /* webpackChunkName: "component---src-templates-specialization-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

